<script>
import searchBar from '@components/searchBar'
import AddFoodModal from "./addFoodModal"
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {title: 'Τρόφιμα', meta: [{name: 'description'}],},
  components: {PaginationRow, searchBar, AddFoodModal},
  mixins: [PaginatedDataMixin],
  data() {
    return {
      foodCategories: [],
      fields: [
        {key: 'name', label:'Όνομα', tdClass:'food-name'},
        {key: 'kcal', label: 'Ενέργεια', formatter: (value, item) => {
            return value + ' kcal'
          }},
        {key: 'carbohydrates', label: 'Υδατάνθρακες', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'proteins', label: 'Πρωτεϊνες', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'fats', label: 'Λίπη', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'quantity', label: 'Ποσότητα', formatter: (key, value, item) => {
            let print = key;
            // check if measurementUnit ID isn't grammars, if so then change key to greek letters instead of 0.5
            if(item.measurementUnit.id !== 8){
              print = this.replaceFoodUnits(key);
            }
            let measure = this.foodMeasures.find(x=> x.id === item.measurementUnit);
            return measure.displayName ? print + ' ' + measure.displayName : print;
          }}
      ],
      foodModal: {
          foodMealCategory:{
              isBreakfast: false,
              isDekatiano: false,
              isMesimeriano: false,
              isApogeumatino: false,
              isVradino:  false
          },
      },

      }
  },

  async created(){
    this.api.url = '/food/';
    this.api.query.limit = 15;
    Vue.set(this.api.filters, 'list', 0);
      // load foods and save to state
      await this.fetchFoods(); // load foods from vuex store or await for api call!
      this.fetchFoodMeasures();
      this.getData();
  },

  methods: {

    fetchFoodMeasures(){
        this.$axios.get(`/food/foodCategories`).then(result=>{
            this.foodCategories = result.data || [];
        });
    },

    filterSearchRadius(){
        this.api.page  = 1;
        this.getData();
    },

    openEditFoodModal(record, index){
        this.foodModal = {...this.foodModal, ...record};
        if(!this.foodModal.microNutrients){
          for(let microNutrient of this.microNutrientFields){
            this.foodModal.microNutrients[microNutrient] = 0;
          }
        }else{
          for(let microNutrient of this.microNutrientFields){
            this.foodModal.microNutrients[microNutrient] = this.foodModal.microNutrients[microNutrient] || 0;
          }
        }
        this.foodModal.action = "UPDATE";
        this.$refs['food-modal'].show();
    },

    openCreateFoodModal(){
        this.foodModal = {
            id: null,
            action: 'CREATE',
            name: '',
            kcal: null,
            measurementUnit: 0,
            carbohydrates: null,
            proteins: null,
            fats: null,
            quantity: null,
            category: 0,
            isSystem: false,
            isVegan: false,
            isFasting: false,
            isVeganOnly: false,
            foodMealCategory:{
                isBreakfast: false,
                isDekatiano: false,
                isMesimeriano: false,
                isApogeumatino: false,
                isVradino:  false
            },
            userId: null,
            list: 4,
            microNutrients: {}
        };
        for(let microNutrient of this.microNutrientFields){
          this.foodModal.microNutrients[microNutrient] = 0;
        }

        this.$refs['food-modal'].show();
    },

    closeFoodModal(){
        this.$refs['food-modal'].hide();
        this.getData();
    }


  }
}
</script>
<style lang="scss">
    #foods{
        .food-name{
            width:30%;
        }
        thead{
            font-weight: normal;
            background-color: $color_primary;
            color: #fff;
        }
        tbody tr{
            cursor: pointer;
        }

        #food-modal .select-label{
            width:100%!important;
        }
        #food-modal{
            padding-bottom:0!important;
        }
        .modal-footer{
            padding:5px 0!important;
        }
        tr:nth-child(even){
            background-color: $color_light_bg;
        }
    }
    .pagination{
        margin-top:20px;
    }


</style>

<template>
<div id="food-list">
  <searchBar>
    <template v-slot:forms>
      <form class="form-inline">
        <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
            <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
        </div>
        <div class="form-group mx-sm-3">
            <label for="list-select" class="mr-1">Λίστα:</label>
            <select id="list-select" v-model="api.filters.list" class="custom-select" @change="filterSearchRadius">
              <option value='0'>Πίνακας athlisis</option>
              <option value='1'>Τριχοπούλου (μικροθρεπτικά)</option>
              <option value="2">USDA Greece</option>
              <option value="3">Συνταγές</option>
              <option value="4">Τα τρόφιμά μου</option>
              <option value="5">aCloud τρόφιμα</option>
            </select>
        </div>
      </form>
    </template>
    <template v-slot:buttons>
      <div class="text-lg-right mt-3 mt-lg-0">
          <button type="button" class="btn btn-primary mr-1" @click="openCreateFoodModal"><i class="fe-plus-circle"></i> Προσθήκη νέου τροφίμου</button>
      </div>
    </template>
  </searchBar>
  <!-- end on top bar -->
  <div v-if="api.rows.length > 0 " id="foods" class="row foods-table">
      <b-card class="col-md-12">
            <b-alert v-if="api.rows.length === 0" show variant="warning">Δεν βρέθηκαν τρόφιμα!</b-alert>
            <!--{{historyMeasurements}}-->
            <div v-if="api.rows.length > 0 && foodMeasures.length > 0" class="table-responsive mb-0">
                <b-table id="food-list" :items="api.rows" :fields="fields" class="table table-hover table-borderless"
                         responsive="sm" :current-page="api.page"
                         @row-clicked="openEditFoodModal">
                </b-table>



              <pagination-row :api="api" @fetchData="getData"></pagination-row>
            </div>
        </b-card>
  </div>
  <!-- end row -->
  <b-modal id="food-modal" ref="food-modal" :title="foodModal.action === 'CREATE' ? 'Δημιουργία τροφίμου' : 'Επεξεργασία τροφίμου'"
           size="lg" hide-footer body-class="p-3">
      <add-food-modal
              :food-modal="foodModal"
              :food-categories="foodCategories"
              @setFoodModal="(value) => { this.foodModal = value }"
              @closeFoodModal="closeFoodModal"></add-food-modal>
  </b-modal>

</div>


</template>
