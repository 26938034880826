<template>
    <div id="add-food-modal">
<!--        <b-alert show variant="warning">Όταν κάνετε εισαγωγή τροφίμου, θα πρέπει να ρυθμίζετε την ποσότητα ώστε να βασίζεται σε 1 ισοδύναμο.-->
<!--            Για να εισάγετε σωστά τα δεδομένα, σκεφτείτε: <br>-->
<!--            1 ισοδύναμο του τροφίμου μου ισούται με <strong>x ποσότητα </strong> από <strong>y μονάδα μέτρησης</strong></b-alert>-->

        <b-form @submit.prevent="updateFood">

            <div class="row">
                <div class="col-md-6">
                    <b-form-group id="edit-name" label="Όνομα τροφίμου" label-for="edit-name">
                        <b-input-group>
                            <b-form-input id="edit-name" v-model="foodModal.name" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="edit-kcal" label="Ενέργεια" label-for="edit-kcal">
                        <b-input-group append="kcal" >
                            <b-form-input id="edit-kcal" v-model="foodModal.kcal" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" @input="changeKcal"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-group id="list-select" label="Λίστα τροφίμου" label-for="list-select" >
                        <select v-model="foodModal.list" class="custom-select" :disabled="true">
                            <option value='0'>Πίνακας athlisis</option>
                            <option value='1'>Τριχοπούλου (μικροθρεπτικά)</option>
                            <option value="2">USDA Greece</option>
                           <option value="3">Συνταγές</option>
                          <option value="4">Τα τρόφιμά μου</option>
                          <option value="5">aCloud τρόφιμα</option>
                        </select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <b-form-group id="edit-category" label="Κατηγορία τροφίμου" label-for="edit-category">
                        <label class="select-label">
                            <select v-model="foodModal.category" class="custom-select" :disabled="foodModal.isSystem || !!foodModal.recipeId">
                                <option v-for="item in foodCategories" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </label>
                    </b-form-group>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4">
                    <b-form-group id="edit-carbs" label="Υδατάνθρακες" label-for="edit-carbs">
                        <b-input-group append="g" >
                            <b-form-input id="edit-carbs" v-model="foodModal.carbohydrates" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" @input="foodModal.carbohydrates = replaceCommaWithDot(foodModal.carbohydrates)" @change="updateFoodModalKcal()" ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-md-4">
                    <b-form-group id="edit-proteins" label="Πρωτεϊνες" label-for="edit-proteins">
                        <b-input-group append="g" >
                            <b-form-input id="edit-proteins" v-model="foodModal.proteins" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" @input="foodModal.proteins = replaceCommaWithDot(foodModal.proteins)" @change="updateFoodModalKcal()"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-md-4">
                    <b-form-group id="edit-fats" label="Λίπη" label-for="edit-fats">
                        <b-input-group append="g" >
                            <b-form-input id="edit-fats" v-model="foodModal.fats" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" @input="foodModal.fats = replaceCommaWithDot(foodModal.fats)" @change="updateFoodModalKcal()" ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group id="edit-quantity" label="Ποσότητα" label-for="edit-quantity">
                        <b-input-group>
                            <b-form-input id="edit-quantity" v-model="foodModal.quantity" type="text" :disabled="foodModal.isSystem || !!foodModal.recipeId" @input="foodModal.quantity = replaceCommaWithDot(foodModal.quantity)"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="edit-measure" label="Μονάδα μέτρησης" label-for="edit-measure">
                        <label class="select-label">
                            <select v-model="foodModal.measurementUnit" class="custom-select" :disabled="foodModal.isSystem || !!foodModal.recipeId">
                                <option v-for="item in foodMeasures" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </label>
                    </b-form-group>
                </div>
            </div>

            <b-form-group label="Επιλογή τύπου τροφίμου:">
                <div class="row">
                    <div class="col-md-4">
                        <b-form-checkbox id="isPrwino" v-model="foodModal.foodMealCategory.isBreakfast" name="isBreakfast" :disabled="foodModal.isSystem" >Πρωινό</b-form-checkbox>
                    </div>
                    <div class="col-md-4">
                        <b-form-checkbox id="isDekatiano" v-model="foodModal.foodMealCategory.isDekatiano" name="isDekatiano" :disabled="foodModal.isSystem" >Δεκατιανό</b-form-checkbox>
                    </div>
                    <div class="col-md-4">
                        <b-form-checkbox id="isMesimeriano" v-model="foodModal.foodMealCategory.isMesimeriano" name="isMesimeriano" :disabled="foodModal.isSystem" >Μεσημεριανό</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <b-form-checkbox id="isApogeumatino" v-model="foodModal.foodMealCategory.isApogeumatino" name="isApogeumatino" :disabled="foodModal.isSystem" >Απογευματινό</b-form-checkbox>
                    </div>
                    <div class="col-md-4">
                        <b-form-checkbox id="isVradino" v-model="foodModal.foodMealCategory.isVradino" name="isVradino" :disabled="foodModal.isSystem" >Βραδινό</b-form-checkbox>
                    </div>
                </div>
            </b-form-group>

            <b-form-group label="Επιλογή ιδιοτήτων:">
                <div class="row">
                    <div class="col-md-4">
                        <b-form-checkbox id="isVegan" v-model="foodModal.isVegan" name="isVegan" :disabled="foodModal.isSystem" >Είναι vegan;</b-form-checkbox>
                    </div>
                    <div class="col-md-4">
                        <b-form-checkbox id="isFasting" v-model="foodModal.isFasting" name="isFasting" :disabled="foodModal.isSystem" >Είναι νηστίσιμο;</b-form-checkbox>
                    </div>
<!--                    <div class="col-md-4">-->
<!--                        <b-form-checkbox id="isVeganOnly" v-model="foodModal.isVeganOnly" name="isVeganOnly" :disabled="foodModal.isSystem" >Μόνο για vegan;</b-form-checkbox>-->
<!--                    </div>-->
                </div>
            </b-form-group>

          <b-button  v-if="foodModal.microNutrients && Object.keys(foodModal.microNutrients).length > 0" v-b-toggle.collapse-3 class="m-1" variant="link">Μικροθρεπτικά</b-button>
          <b-collapse  v-if="foodModal.microNutrients && Object.keys(foodModal.microNutrients).length > 0" id="collapse-3" class="mb-2" style="max-height:300px;overflow-y:scroll;overflow-x:hidden;">
            <div class="row">
              <div v-for="micronutrient of microNutrientFields" :key="micronutrient" class="col-md-4">
                <label>{{microNutrientTranslation[micronutrient]}}
                <b-form-input v-model="foodModal.microNutrients[micronutrient]" :disabled="foodModal.isSystem || !!foodModal.recipeId"/></label>
              </div>
            </div>
          </b-collapse>

            <b-form-group id="button-group" class="modal-footer">
                <div class="row">
                    <div class="col-md-3 text-left">
                        <b-button v-if="(foodModal.action === 'UPDATE' && !foodModal.isSystem) && !foodModal.recipeId" variant="danger" class="btn m-1 delete-food" @click="deleteFood">
                            <i class="fe-trash-2"></i>Διαγραφή
                        </b-button>
                    </div>
                    <div class="col-md-9 text-right" :class="foodModal.action === 'CREATE' ? 'col-md-12' : ''">
                        <!--eslint-disable-next-line-->
                        <b-button variant="light" class="btn btn-light m-1" @click="$emit('closeFoodModal')">Κλείσιμο</b-button>
                        <b-button v-if="foodModal.action === 'CREATE'" type="submit" variant="primary" class="btn btn-primary m-1"><i class="fe-plus-circle"></i> Προσθήκη τροφίμου</b-button>

                        <save-button v-if="foodModal.action === 'UPDATE' && !foodModal.isSystem" type="submit"></save-button>

                    </div>

                </div>

            </b-form-group>
            <b-alert v-if="foodModal.isSystem" show variant="danger">Δεν μπορείτε να επεξεργαστείτε τρόφιμα του συστήματος.</b-alert>
            <b-alert v-if="error" show variant="danger"> {{ error }}</b-alert>
        </b-form>
    </div>
</template>

<script>

    import Vue from "vue";
    export default{

        props:{
            foodModal:{
                type: Object,
                required: true
            },

            foodCategories: {
                type: Array,
                required: true
            },


        },

        data(){
            return{
                hasEditedKcal: false,
                error: null,
                isUpdating: false
            }
        },

        methods:{
            changeKcal(){
                this.foodModal.kcal = this.replaceCommaWithDot(this.foodModal.kcal);
                this.hasEditedKcal = true;
            },
            fixMicronutrientVals(){

              for(let item of Object.keys(this.foodModal.microNutrients)){

                this.foodModal.microNutrients[item] = parseFloat(this.foodModal.microNutrients[item].toString().replace(',' ,'.'));
                if(isNaN(this.foodModal.microNutrients[item])){
                  this.foodModal.microNutrients[item] = 0;
                }
              }
            },

            updateFood(){

              if(this.isUpdating) return;
                this.error = null;

                if(!this.hasEditedKcal) this.updateFoodModalKcal();

                if(!(parseInt(this.foodModal.category) > 0)){
                    this.error = 'Επιλέξτε μια κατηγορία για το τρόφιμο';
                    return;
                }

                this.fixMicronutrientVals();

                this.isUpdating = true;
                if(this.foodModal.action === 'CREATE'){
                    this.$axios.post(`/food/`, {food: this.foodModal}).then(async result => {
                        await this.$store.dispatch('food/addNewFood', result.data);
                        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο προστέθηκε επιτυχώς!'});
                        this.$emit('closeFoodModal');

                    }).catch(e =>{
                      this.isUpdating= false;
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η δημιουργία του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    });
                }

                if(this.foodModal.action === 'UPDATE'){
                    this.$axios.put(`/food/`, {food: this.foodModal}).then(async result => {
                        await this.$store.dispatch('food/updateFood', result.data);
                        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο ενημερώθηκε επιτυχώς!'});
                        this.$emit('closeFoodModal');

                    }).catch(e =>{
                      this.isUpdating = false;
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    });
                }
            },

            updateFoodModalKcal(){
                if(!this.hasEditedKcal) this.foodModal.kcal = this.foodModal.carbohydrates * 4 + this.foodModal.proteins * 4 + this.foodModal.fats *9;
            },

            deleteFood(){
              this.$dialog.confirm({
                title:  this.$t('verifyDeletion.title'),
                body: this.$t('verifyDeletion.message')
              }, {
                html: true,
                loader: true,
                okText: this.$t('verifyDeletion.ok'),
                cancelText:  this.$t('verifyDeletion.cancel'),
                backdropClose: true
              }).then((dialog) => {
                this.$axios.delete(`/food/${this.foodModal.id}`).then(async result => {
                  await this.$store.dispatch('food/deleteFood',  this.foodModal.id);
                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
                  this.$emit('closeFoodModal');
                }).catch(e=>{
                  this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
                }).finally(()=>{
                  dialog.close();
                });
              }).catch(()=>{});
            },
        }
    }

</script>
